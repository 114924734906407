.home,
.termsConditions,
.privacyPolicy {
  @include breakpoint-up {
    &:not(.is-scrolling) {
      .nav {
        &__list {
          padding-bottom: get-vw(1200, 30px);

          li {
            display: list-item;
          }

          .menu-item {
            display: none;
          }
        }
      }
    }

    &.is-scrolling .nav__list .menu-item {
      display: list-item;
    }
  }
}

.home {
  main {
    @include breakpoint-down {
      padding-bottom: 0;
    }
  }
}

.home {
  .header-intro {
    padding-bottom: 0;
    height: 80vh;

    @media(max-width: 1100px) {
      height: 90vh;
    }

    @media(max-width: 768px) {
      height: 50vh;
    }

    @media(max-width: 700px) {
      height: 70vh;
    }

    @media(max-width: 500px) {
      height: 30vh;
    }

    @media(max-width: 420px) {
      height: 50vh;
    }

    @media(max-width: 340px) {
      height: 30vh;
    }

    img, video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      z-index: -1;
      object-fit: cover;

      @media(max-width: 500px) {
        height: 60vh;
      }

      @media(max-width: 420px) {
        height: 70vh;
      }

      @media(max-width: 340px) {
        height: 50vh;
      }
    }

    .txt-medium {
      font-size: 4rem;
      max-width: 70%;
      text-transform: uppercase;

      @media(max-width: 992px) {
        font-size: 3rem;
        margin-top: 50px;
      }

      @media(max-width: 768px) {
        margin-top: 30px;
        max-width: 90%;
      }

      @media(max-width: 500px) {
        font-size: 2rem;
      }

      @media(max-width: 340px) {
        font-size: 1.5rem;
      }
    }
  }
}