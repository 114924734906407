.posts {
    width: 100%;
    @extend .wrapper;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint-up {
        padding-left: get-vw(1200, 15px);
        padding-right: get-vw(1200, 15px);
    }

    & > div {
        float: left;
        margin-left: get-vw(1200, 15px);
        margin-right: get-vw(1200, 15px);
        @include breakpoint-down {
            width: 100%;
        }
    }

    .post-item {
        @include breakpoint-up {
            width: calc(50% - #{get-vw(1200, 30px)});
        }
    }
}
