.shortlist {
  &__toggle {
    position: relative;

    @include breakpoint-down {
      padding-bottom: get-vw(320, 20px) !important;
      border-bottom: 1px solid black;
    }

    @include breakpoint-up {
      display: none;
    }

    .toggle {
      top: get-vw(320, 12px);
    }
  }

  &__item {
    border-top: 1px solid black;
    padding: get-vw(1200, 11px) 0;

    @include breakpoint-down {
      padding: get-vw(320, 8px) 0;

      &:not(.shortlist__toggle) {
        display: none;
      }
    }

    a {
      @extend .decorated-link;
    }
  }

  &.is-open {
    @include breakpoint-down {
      .shortlist__item {
        display: block;
      }

      .shortlist__toggle {
        border-bottom: none;
      }
    }
  }
}
