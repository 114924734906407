.form {
  width: 100%;

  &__input {
    border: 0;
    border-bottom: 1px solid black;
    background: transparent;
    outline: 0;
    @extend .txt-medium;
    display: block;
    width: 100%;
    padding: get-vw(1200, 3px) 0;
  }

  .button {
    background-color: transparent;
  }
}
