body.results {
    section.judges {
        @extend .wrapper;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 100px;

        @media(max-width: 768px) {
            display: flex;
            flex-direction: column;
        }

        .entry__judge {
            padding-top: 20px;
            border-top: 1px solid black;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .entry__judge-info {
                display: flex;
                flex-direction: column;

                .entry__judge-name {
                    display: block;
                    margin-bottom: 40px;
                    text-transform: uppercase;
                }

                .entry__judge-role {
                    margin-bottom: 5px;
                }
            }

            img {
                height: 250px;

                @media(max-width: 992px) {
                    height: 200px;
                }

                @media(max-width: 830px) {
                    height: 150px;
                }

                @media(max-width: 768px) {
                    height: 200px;
                }
            }
        }
    }
}