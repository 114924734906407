.logo {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;

  @include breakpoint-down {
    display: none;
  }

  &__wrapper {
    position: absolute;
    left: get-vw(1200, 30px);
    bottom: get-vw(1200, 30px);
  }

  span {
    float: right;
    margin-right: get-vw(1200, -10px);
  }
}
