.img-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    display: block;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // object-fit: cover;
    }
}
