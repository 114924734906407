.header {
  top: 0;
  left: 0;
  padding-top: get-vw(1200, 20px);
  padding-bottom: get-vw(1200, 20px);
  border-bottom: 1px solid black;
  line-height: 1em;
  position: fixed;
  z-index: 10;

  &.background-inherit {
    background-color: inherit;
    will-change: background-color;
  }

  &.home-scroll {
    background-color: $orange_background;
  }

  .home.active-section-awards:not(.nav-is-hovered) & {
    background-color: $purple;
  }

  .home.active-section-stories:not(.nav-is-hovered) & {
    background-color: $green;
  }

  .home.active-section-about:not(.nav-is-hovered) & {
    background-color: $yellow;
  }

  @include breakpoint-down {
    padding-bottom: 0;

    &.wrapper {
      padding-left: 0;
      padding-right: 0;
    }

    &.columns > div:first-child {
      margin-bottom: 0;
    }
  }

  &__logo {
    opacity: 1;
    transition: opacity 0.3s ease;

    @include breakpoint-down {
      position: relative;
      z-index: 10;

      &-wrapper {
        position: relative;
        padding: get-vw(320, 20px);
      }
    }

    @include breakpoint-up {
      .home:not(.is-scrolling) & {
        opacity: 0;
      }
    }
  }

  &__menu-icon-wrapper {
    display: none;

    @include breakpoint-down {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
      padding: get-vw(320, 20px);
    }

    svg {
      transition: transform 0.4s ease;

      .menu-is-open & {
        transform: rotate(45deg);
      }
    }
  }

  @include breakpoint-up {
    .no-touchevents &:hover {
      .nav__list {
        padding-bottom: get-vw(1200, 30px);

        li {
          display: list-item;

          &.menu-item {
            display: none;
          }
        }
      }
    }
  }
}
