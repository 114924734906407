.notice {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  &.is-active {
    transform: scale(1);
    opacity: 1;
  }

  &__message {
    background-color: $orange;
    padding: get-vw(1200, 20px) get-vw(1200, 30px);
    text-align: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);

    @include breakpoint-down {
      padding: get-vw(320, 20px);
    }
  }
}
