.caption {
    align-items: flex-start;
    display: flex;
    margin-top: get-vw(1200, 12px);

    &::before {
        content: '•';
        font-size: get-vw(320, 10px);
        margin-right: 1em;

        @include breakpoint-up {
            font-size: get-vw(1200, 12px);
        }
    }
}
