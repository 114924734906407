.nav {
    @include breakpoint-down {
        position: fixed;
        width: 100% !important;
        height: 100%;
        background-color: white;
        display: -webkit-flex; /* NEW */
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        margin-left: 0 !important;
        z-index: 9;
        pointer-events: none;
        transition: clip-path 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
        -webkit-transition: -webkit-clip-path 0.5s
            cubic-bezier(0.59, 0.01, 0.28, 1);
        clip-path: inset(0 0 100% 0);
        -webkit-clip-path: inset(0 0 100% 0);

        .menu-is-open & {
            pointer-events: all;
            clip-path: inset(0 0 0 0);
            -webkit-clip-path: inset(0 0 0 0);
        }
    }

    &__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        @media(max-width: 768px) {
            display: flex;
            flex-direction: column;
        }

        .nav__register-wrapper {
            display: flex;
            align-items: center;

            @include breakpoint-down {
                padding: 0 get-vw(320, 20px) get-vw(320, 20px);
                position: absolute;
                bottom: 0;
                left: 0;
            }

            @media(max-width: 400px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    &__list {
        @include breakpoint-down {
            padding: get-vw(320, 20px);
            font-size: get-vw(320, 20px);
            line-height: 0.9em;
            display: flex;
            flex-direction: column;
        }

        li {
            position: relative;
            line-height: 1em;

            @include breakpoint-down {
                display: inline;
            }

            @include breakpoint-up {
                &:not(.is-active) {
                    display: none;
                }
            }

            &.menu-item {
                display: none;
            }

            span {
                width: 10px;
                height: 10px;
                border-radius: 10px;
                position: absolute;
                left: get-vw(1200, -20px);
                top: 0;
                bottom: 0;
                margin: auto;

                @include breakpoint-down {
                    display: none;
                }
            }

            .no-touchevents &:hover:not(.is-active) {
                span {
                    border: 1px solid black;
                }
            }

            &.is-active,
            &.menu-item {
                @include breakpoint-down {
                    background-repeat: repeat-x;
                    background-size: 1px 1px;
                    background-position: 0 90%;
                    background-image: -webkit-linear-gradient(
                        top,
                        black 75%,
                        black 75%
                    );
                    background-image: -o-linear-gradient(
                        top,
                        black 75%,
                        black 75%
                    );
                    background-image: linear-gradient(
                        to bottom,
                        black 75%,
                        black 75%
                    );
                }

                span {
                    background-color: black;
                }
            }
        }
    }

    &__social {
        @include breakpoint-down {
            margin-right: 40px;
        }

        @include breakpoint-up {
            margin-right: 20px;
        }

        @media(max-width: 400px) {
            margin-bottom: 20px;
            margin-right: 0;
        }
    }

    &__clip-mask {
        @include breakpoint-up {
            display: none;
        }
    }

    &__register-button {
        a {
            font-size: 18px;
        }
    }
}
