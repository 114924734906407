.margin-bottom {
  margin-bottom: get-vw(320, 30px);

  @include breakpoint-up {
    margin-bottom: get-vw(1200, 55px);
  }
}

.margin-top {
  margin-top: get-vw(320, 20px);

  @include breakpoint-up {
    margin-top: get-vw(1200, 55px);
  }
}

.large-margin-bottom {
  margin-bottom: get-vw(320, 60px);

  @include breakpoint-up {
    margin-bottom: get-vw(1200, 120px);
  }
}

.small-margin-bottom {
  margin-bottom: get-vw(320, 15px);

  @include breakpoint-up {
    margin-bottom: get-vw(1200, 26px);
  }
}

.heading-margin-bottom {
  margin-bottom: get-vw(320, 20px);

  @include breakpoint-up {
    margin-bottom: get-vw(1200, 38px);
  }
}

.block-margin-bottom {
  margin-bottom: get-vw(320, 15px);

  @include breakpoint-up {
    margin-bottom: get-vw(1200, 30px);
  }
}
