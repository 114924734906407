.columns {
  @include breakpoint-down {
    & > div:first-child:not(.shortlist__item__title):not(.header__logo-wrapper) {
      margin-bottom: get-vw(320, 20px);

      &.is-sticky {
        margin-bottom: get-vw(320, 30px);
      }
    }
  }

  @include breakpoint-up {
    display: flex;
    align-items: flex-start;

    &--right {
      justify-content: flex-end;
    }

    &--align-baseline {
      align-items: flex-end;
    }

    & > div:nth-child(-1n+2) {
      width: calc(50% - #{get-vw(1200, 15px)});
    }

    & > div:nth-child(2) {
      margin-left: get-vw(1200, 30px);
    }

    p {
      margin-right: 2em;
    }
  }
}
