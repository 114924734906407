.slant {
  position: relative;
  z-index: 1;

  &::before {
    background: inherit;
    top: 0;
    content: '';
    display: block;
    // display: none;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    transition: transform 0.8s cubic-bezier(0.59, 0.01, 0.28, 1);
    transform: skewY(9deg);
    transform-origin: 100%;
  }

  &.is-active {
    &::before {
      transform: skewY(10deg);

      @include breakpoint-up {
        transform: skewY(14deg);
      }
    }
  }

  &--awards {
    background-color: $purple;
    padding-top: get-vw(320, 20px);
    padding-bottom: get-vw(320, 40px);

    @include breakpoint-up {
      padding-top: get-vw(1200, 20px);
      padding-bottom: get-vw(1200, 100px);
    }

    &::before {
      transform: skewY(-18deg);
      transform-origin: 0%;

      @include breakpoint-up {
        transform: skewY(-16deg);
      }
    }

    &.is-active {
      &::before {
        transform: skewY(-18deg);

        @include breakpoint-up {
          transform: skewY(-19deg);
        }
      }
    }
  }

  &--stories {
    background-color: $green;
    padding-top: get-vw(320, 20px);
    padding-bottom: get-vw(320, 40px);

    @include breakpoint-up {
      padding-top: get-vw(1200, 20px);
      padding-bottom: get-vw(1200, 100px);
    }
  }

  &--about {
    background-color: $yellow;
    padding-top: get-vw(320, 20px);
    padding-bottom: get-vw(320, 40px);

    @include breakpoint-up {
      padding-top: get-vw(1200, 20px);
      padding-bottom: get-vw(1200, 200px);
    }
  }

  &--footer {
    &::before {
      transform: skewY(0deg);
      height: 100%;
    }

    &.is-active {
      &::before {
        transform: skewY(10deg);

        @include breakpoint-up {
          transform: skewY(12deg);
        }
      }
    }
  }
}
