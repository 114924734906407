@charset "UTF-8";
@font-face {
  font-family: "FKGrotesk-Regular";
  src: url("../../fonts/FKGrotesk-Regular.woff2") format("woff2"), url("../../fonts/FKGrotesk-Regular.woff") format("woff"), url("../../fonts/FKGrotesk-Regular.ttf") format("ttf"), url("../../fonts/FKGrotesk-Regular.otf") format("otf");
}
/* stylelint-disable */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  line-height: 1em;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=text],
input[type=password],
textarea,
button {
  font-size: inherit;
  font-family: inherit;
  line-height: 1.2em;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 0;
}

input[type=submit] {
  -webkit-appearance: none;
  border-radius: 0;
}

textarea {
  resize: none;
  height: 1.2em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
  border-bottom: 1px solid black;
}
.company input:-webkit-autofill,
.company input:-webkit-autofill:hover,
.company input:-webkit-autofill:focus,
.company textarea:-webkit-autofill,
.company textarea:-webkit-autofill:hover,
.company textarea:-webkit-autofill:focus,
.company select:-webkit-autofill,
.company select:-webkit-autofill:hover,
.company select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

* {
  box-sizing: border-box;
}

::selection {
  background-color: black;
  color: white;
}

body {
  scroll-behavior: smooth;
  font-family: "FKGrotesk-Regular", sans-serif, sans-serif;
  font-weight: 400;
  color: black;
  background-color: #78e6d0;
  font-size: 18px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
  will-change: background-color;
}
body.menu-is-open {
  position: relative;
  height: 100%;
  overflow: hidden;
}
body.aboutAlan {
  background-color: #f2a900;
}
body.termsConditions, body.resultsByYear, body.results, body.privacyPolicy, body.finalist, body.stories {
  background-color: #d9d9d9;
}
body.stories-index {
  background-color: #00bf6f;
}
body.award {
  background-color: #685bc7;
}

.background-swipe {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.background-swipe .swipe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  transform: scale(3);
  clip-path: polygon(100% 0, 0 0, 100% 0);
  transition: clip-path 0.8s cubic-bezier(0.59, 0.01, 0.28, 1);
  will-change: clip-path;
}
.home:not(.nav-is-hovered) .background-swipe .swipe[data-section=awards] {
  background-color: #685bc7;
  z-index: -3;
}
.home:not(.nav-is-hovered) .background-swipe .swipe[data-section=stories] {
  background-color: #00bf6f;
  z-index: -2;
}
.home:not(.nav-is-hovered) .background-swipe .swipe[data-section=about] {
  background-color: #f2a900;
  z-index: -1;
}

a {
  color: black;
  text-decoration: none;
}

img {
  transition: opacity 0.3s ease;
}
img.lazyload, img.lazyloading {
  opacity: 0;
}
img .lazyloaded {
  opacity: 1;
}

.decorated-link, .judge__company, .shortlist__item a, main .txt-medium a, main .button a, main .form__input a, main p a {
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 94%;
  background-image: -webkit-linear-gradient(top, black 75%, black 75%);
  background-image: -o-linear-gradient(top, black 75%, black 75%);
  background-image: linear-gradient(to bottom, black 75%, black 75%);
}
.decorated-link:hover, .judge__company:hover, .shortlist__item a:hover, main .txt-medium a:hover, main .button a:hover, main .form__input a:hover, main p a:hover {
  background-image: none;
}

ul li {
  line-height: 1.16em;
}

hr {
  margin-bottom: 1.5625vw;
  border: 0;
  border-bottom: 1px solid black;
}
@media (min-width: 768px) {
  hr {
    margin-bottom: 0.9166666667vw;
  }
}
@media (min-width: 768px) {
  hr {
    margin-bottom: 0.9166666667vw;
  }
}

main {
  position: relative;
  padding-top: 7.5vw;
  padding-bottom: 25vw;
}
@media (max-width: 767px) {
  main {
    padding-bottom: 18.75vw;
    padding-top: 25vw;
  }
}
@media (min-width: 768px) {
  .home main {
    padding-top: 15vw;
    padding-bottom: 0;
  }
  .termsConditions main, .privacyPolicy main {
    padding-top: 15vw;
  }
}
main p:not(:last-child) {
  margin-bottom: 1em;
}
main .txt-medium a, main .button a, main .form__input a {
  text-decoration: none;
}
main .txt-medium a.button, main .button a.button, main .form__input a.button {
  background: none;
}
main .txt-medium ul, main .button ul, main .form__input ul {
  list-style: disc;
  padding-left: 1em;
  margin-bottom: 1em;
}
main .txt-medium ol, main .button ol, main .form__input ol {
  list-style-type: decimal;
  padding-left: 1em;
  margin-bottom: 1em;
}

footer {
  position: relative;
  padding-top: 1.6666666667vw;
  padding-bottom: 1.6666666667vw;
  background-color: white;
  z-index: 1;
}
@media (max-width: 767px) {
  footer {
    padding-top: 6.25vw;
    padding-bottom: 6.25vw;
  }
}
footer a:hover {
  text-decoration: underline;
}
.no-touchevents footer .button:hover {
  background-color: black;
  color: white;
}

@media (min-width: 768px) {
  .intro, .header-intro {
    height: 100vh;
    padding-bottom: 62vw;
  }
}
@media (max-width: 767px) {
  .intro, .header-intro {
    margin-bottom: 31.25vw;
  }
}

.pos-relative {
  position: relative;
}

.footer-social {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .is-sticky {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 8.3333333333vw;
    z-index: 9;
  }
}

.share span {
  line-height: 1.5em;
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}
@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}
.award .colour,
.award .colour img,
.home .colour,
.home .colour img,
.aboutAlan .colour,
.aboutAlan .colour img,
.thePrize .colour,
.thePrize .colour img {
  filter: none !important;
}
.award .full,
.home .full,
.aboutAlan .full,
.thePrize .full {
  object-fit: contain;
}

.body-copy ol {
  list-style: decimal;
  list-style-position: inside;
}
.body-copy ol li {
  margin-bottom: 1em;
}

.reveal {
  opacity: 0;
  transition: opacity 1s ease;
}
.reveal--ed {
  opacity: 1;
}

#tdp-logo {
  width: 8.3333333333vw;
  height: 8.3333333333vw;
  height: auto;
}
@media (max-width: 767px) {
  #tdp-logo {
    width: 18.75vw;
    height: 18.75vw;
  }
}

.txt-large {
  font-family: "FKGrotesk-Regular", sans-serif;
  font-weight: 400;
  font-size: 12.5vw;
  line-height: 1em;
}
@media (min-width: 768px) {
  .txt-large {
    font-size: 4.5vw;
  }
}

.txt-medium, .button, .form__input {
  font-family: "FKGrotesk-Regular", sans-serif;
  font-weight: 400;
  font-size: 5vw;
  line-height: 1.16em;
}
@media (min-width: 768px) {
  .txt-medium, .button, .form__input {
    font-size: 2vw;
  }
}

.txt-small, .video-player__video {
  font-family: "FKGrotesk-Regular", sans-serif;
  font-weight: 400;
  font-size: 4.375vw;
  line-height: 1.16em;
}
@media (min-width: 768px) {
  .txt-small, .video-player__video {
    font-size: 1.4166666667vw;
  }
}

.txt-uppercase {
  text-transform: uppercase;
  letter-spacing: -0.018em;
}

@media (max-width: 767px) {
  .txt-mobile-heading {
    font-size: 12.5vw;
    line-height: 0.9em;
  }
}

.txt--heading {
  display: block;
  letter-spacing: -0.018em;
  margin-bottom: 1.25vw;
  padding-top: 1.25vw;
  position: relative;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .txt--heading {
    margin-bottom: 1.5625vw;
    padding-top: 1.5625vw;
  }
}
.txt--heading:before {
  background: #000;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.margin-bottom {
  margin-bottom: 9.375vw;
}
@media (min-width: 768px) {
  .margin-bottom {
    margin-bottom: 4.5833333333vw;
  }
}

.margin-top {
  margin-top: 6.25vw;
}
@media (min-width: 768px) {
  .margin-top {
    margin-top: 4.5833333333vw;
  }
}

.large-margin-bottom {
  margin-bottom: 18.75vw;
}
@media (min-width: 768px) {
  .large-margin-bottom {
    margin-bottom: 10vw;
  }
}

.small-margin-bottom {
  margin-bottom: 4.6875vw;
}
@media (min-width: 768px) {
  .small-margin-bottom {
    margin-bottom: 2.1666666667vw;
  }
}

.heading-margin-bottom {
  margin-bottom: 6.25vw;
}
@media (min-width: 768px) {
  .heading-margin-bottom {
    margin-bottom: 3.1666666667vw;
  }
}

.block-margin-bottom {
  margin-bottom: 4.6875vw;
}
@media (min-width: 768px) {
  .block-margin-bottom {
    margin-bottom: 2.5vw;
  }
}

.header {
  top: 0;
  left: 0;
  padding-top: 1.6666666667vw;
  padding-bottom: 1.6666666667vw;
  border-bottom: 1px solid black;
  line-height: 1em;
  position: fixed;
  z-index: 10;
}
.header.background-inherit {
  background-color: inherit;
  will-change: background-color;
}
.header.home-scroll {
  background-color: #fdc843;
}
.home.active-section-awards:not(.nav-is-hovered) .header {
  background-color: #685bc7;
}
.home.active-section-stories:not(.nav-is-hovered) .header {
  background-color: #00bf6f;
}
.home.active-section-about:not(.nav-is-hovered) .header {
  background-color: #f2a900;
}
@media (max-width: 767px) {
  .header {
    padding-bottom: 0;
  }
  .header.wrapper, body.results section.header.judges, .header.posts {
    padding-left: 0;
    padding-right: 0;
  }
  .header.columns > div:first-child {
    margin-bottom: 0;
  }
}
.header__logo {
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media (max-width: 767px) {
  .header__logo {
    position: relative;
    z-index: 10;
  }
  .header__logo-wrapper {
    position: relative;
    padding: 6.25vw;
  }
}
@media (min-width: 768px) {
  .home:not(.is-scrolling) .header__logo {
    opacity: 0;
  }
}
.header__menu-icon-wrapper {
  display: none;
}
@media (max-width: 767px) {
  .header__menu-icon-wrapper {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    padding: 6.25vw;
  }
}
.header__menu-icon-wrapper svg {
  transition: transform 0.4s ease;
}
.menu-is-open .header__menu-icon-wrapper svg {
  transform: rotate(45deg);
}
@media (min-width: 768px) {
  .no-touchevents .header:hover .nav__list {
    padding-bottom: 2.5vw;
  }
  .no-touchevents .header:hover .nav__list li {
    display: list-item;
  }
  .no-touchevents .header:hover .nav__list li.menu-item {
    display: none;
  }
}

@media (max-width: 767px) {
  .nav {
    position: fixed;
    width: 100% !important;
    height: 100%;
    background-color: white;
    display: -webkit-flex;
    /* NEW */
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    margin-left: 0 !important;
    z-index: 9;
    pointer-events: none;
    transition: clip-path 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
    -webkit-transition: -webkit-clip-path 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
    clip-path: inset(0 0 100% 0);
    -webkit-clip-path: inset(0 0 100% 0);
  }
  .menu-is-open .nav {
    pointer-events: all;
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(0 0 0 0);
  }
}
.nav__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .nav__inner {
    display: flex;
    flex-direction: column;
  }
}
.nav__inner .nav__register-wrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .nav__inner .nav__register-wrapper {
    padding: 0 6.25vw 6.25vw;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
@media (max-width: 400px) {
  .nav__inner .nav__register-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .nav__list {
    padding: 6.25vw;
    font-size: 6.25vw;
    line-height: 0.9em;
    display: flex;
    flex-direction: column;
  }
}
.nav__list li {
  position: relative;
  line-height: 1em;
}
@media (max-width: 767px) {
  .nav__list li {
    display: inline;
  }
}
@media (min-width: 768px) {
  .nav__list li:not(.is-active) {
    display: none;
  }
}
.nav__list li.menu-item {
  display: none;
}
.nav__list li span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  left: -1.6666666667vw;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media (max-width: 767px) {
  .nav__list li span {
    display: none;
  }
}
.no-touchevents .nav__list li:hover:not(.is-active) span {
  border: 1px solid black;
}
@media (max-width: 767px) {
  .nav__list li.is-active, .nav__list li.menu-item {
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 90%;
    background-image: -webkit-linear-gradient(top, black 75%, black 75%);
    background-image: -o-linear-gradient(top, black 75%, black 75%);
    background-image: linear-gradient(to bottom, black 75%, black 75%);
  }
}
.nav__list li.is-active span, .nav__list li.menu-item span {
  background-color: black;
}
@media (max-width: 767px) {
  .nav__social {
    margin-right: 40px;
  }
}
@media (min-width: 768px) {
  .nav__social {
    margin-right: 20px;
  }
}
@media (max-width: 400px) {
  .nav__social {
    margin-bottom: 20px;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .nav__clip-mask {
    display: none;
  }
}
.nav__register-button a {
  font-size: 18px;
}

.grid {
  width: 100%;
  padding-left: 6.25vw;
  padding-right: 6.25vw;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2.5vw;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
  }
}

.wrapper, body.results section.judges, .posts {
  width: 100%;
  padding-left: 6.25vw;
  padding-right: 6.25vw;
}
@media (min-width: 768px) {
  .wrapper, body.results section.judges, .posts {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
  }
}

.shortlist__toggle {
  position: relative;
}
@media (max-width: 767px) {
  .shortlist__toggle {
    padding-bottom: 6.25vw !important;
    border-bottom: 1px solid black;
  }
}
@media (min-width: 768px) {
  .shortlist__toggle {
    display: none;
  }
}
.shortlist__toggle .toggle {
  top: 3.75vw;
}
.shortlist__item {
  border-top: 1px solid black;
  padding: 0.9166666667vw 0;
}
@media (max-width: 767px) {
  .shortlist__item {
    padding: 2.5vw 0;
  }
  .shortlist__item:not(.shortlist__toggle) {
    display: none;
  }
}
@media (max-width: 767px) {
  .shortlist.is-open .shortlist__item {
    display: block;
  }
  .shortlist.is-open .shortlist__toggle {
    border-bottom: none;
  }
}

@media (max-width: 767px) {
  .columns > div:first-child:not(.shortlist__item__title):not(.header__logo-wrapper) {
    margin-bottom: 6.25vw;
  }
  .columns > div:first-child:not(.shortlist__item__title):not(.header__logo-wrapper).is-sticky {
    margin-bottom: 9.375vw;
  }
}
@media (min-width: 768px) {
  .columns {
    display: flex;
    align-items: flex-start;
  }
  .columns--right {
    justify-content: flex-end;
  }
  .columns--align-baseline {
    align-items: flex-end;
  }
  .columns > div:nth-child(-1n+2) {
    width: calc(50% - 1.25vw);
  }
  .columns > div:nth-child(2) {
    margin-left: 2.5vw;
  }
  .columns p {
    margin-right: 2em;
  }
}

.judge {
  border-top: 1px solid black;
  padding-top: 1.25vw;
}
@media (max-width: 767px) {
  .judge {
    padding-top: 1.5625vw;
  }
}
.judge__profile {
  margin-bottom: 1.25vw;
  position: relative;
}
@media (min-width: 768px) {
  .judge__profile {
    display: flex;
  }
}
.judge__profile > div {
  flex: 1;
}
@media (max-width: 767px) {
  .judge__profile > div {
    margin-bottom: 6.25vw;
  }
}
.judge__profile__txt {
  padding-right: 10px;
}
@media (max-width: 767px) {
  .judge__profile__txt {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .judge__profile__img {
    display: none;
  }
}
.judge__name {
  margin-bottom: 0.5em;
}
@media (max-width: 767px) {
  .judge__name br {
    display: none;
  }
}
.judge__role {
  display: block;
}
.judge__bio {
  padding-top: 1.25vw;
}
@media (max-width: 767px) {
  .judge__bio {
    display: none;
    padding-top: 1.5625vw;
  }
}
.judge.is-open .judge__profile__img,
.judge.is-open .judge__bio {
  display: block;
}

.post-item-results h1 {
  width: 100%;
  font-size: 50px;
  border-bottom: 1px solid black;
  padding-bottom: 2.5vh !important;
  margin-bottom: 2.5vh !important;
}
.post-item-results__title {
  height: auto !important;
}
.post-item-results-border-top {
  border-top: 1px solid black !important;
}
.post-item-results__thumb {
  padding-bottom: 1.5rem !important;
  margin: 0 !important;
}
.post-item-results__thumb img {
  width: 100%;
}

.post-item-results,
.post-item {
  border-top: 1px solid black;
  padding-top: 1.25vw;
}
@media (max-width: 767px) {
  .post-item-results,
.post-item {
    padding-top: 1.5625vw;
  }
}
.post-item-results__title,
.post-item__title {
  height: 4em;
  overflow: hidden;
  position: relative;
  padding-bottom: 1.4166666667vw;
  margin-bottom: 1.25vw;
}
@media (max-width: 767px) {
  .post-item-results__title,
.post-item__title {
    padding-bottom: 3.125vw;
    margin-bottom: 2.8125vw;
  }
}
.post-item-results__title h5,
.post-item__title h5 {
  padding-right: 8.3333333333vw;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.post-item-results__title .arrow-icon,
.post-item__title .arrow-icon {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 767px) {
  .post-item-results__title .arrow-icon,
.post-item__title .arrow-icon {
    top: 0.625vw;
  }
}
.post-item-results-no-border,
.post-item-no-border {
  border-top: 0px solid black;
}
.post-item-results__thumb,
.post-item__thumb {
  margin-bottom: 1.25vw;
  overflow: hidden;
}
@media (min-width: 768px) {
  .post-item-results__thumb,
.post-item__thumb {
    position: relative;
    padding-bottom: 4.5vw;
  }
}
@media (max-width: 767px) {
  .post-item-results__thumb,
.post-item__thumb {
    margin-bottom: 2.8125vw;
  }
}
@media (min-width: 768px) {
  .post-item-results__thumb .caption,
.post-item__thumb .caption {
    height: 3.1666666667vw;
    margin-top: -3.1666666667vw;
    overflow: hidden;
    position: absolute;
    top: 100%;
  }
}

.posts {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .posts {
    padding-left: 1.25vw;
    padding-right: 1.25vw;
  }
}
.posts > div {
  float: left;
  margin-left: 1.25vw;
  margin-right: 1.25vw;
}
@media (max-width: 767px) {
  .posts > div {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .posts .post-item {
    width: calc(50% - 2.5vw);
  }
}

/*
*  video-player.scss
*
*  1.0 - Container
*  2.0 - Video
*  3.0 - VideoJS Overrides
*/
.video-player {
  /*
  *  1.0 - Container
  */
  /*
  *  2.0 - Video
  */
}
.video-player video {
  outline: 0;
}
.video-player__container {
  width: 100%;
  position: relative;
  padding-bottom: 57.1428571429%;
}
.video-player__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  /*
  *  3.0 - Video JS Overrides
  */
  /*
  *  3.0 - Control Bar
  */
  /*
  *  4.0 - Progress Bar
  */
  /*
  *  5.0 - Volume Controls
  */
}
.video-player__video .vjs-big-play-button {
  font-size: 16.6666666667vw;
  border: 0;
  background: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}
.video-player__video .vjs-big-play-button:focus, .video-player__video .vjs-big-play-button:hover {
  outline: 0;
  border-radius: 0;
}
.video-player__video.vjs-playing .vjs-big-play-button {
  display: none;
}
.video-player__video .vjs-button > .vjs-icon-placeholder::before {
  font-size: 2.8em;
  line-height: 1.05em;
}
.video-player__video .vjs-control:focus {
  text-shadow: none;
}
.video-player__video .vjs-control-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.video-player__video .vjs-progress-control:hover .vjs-progress-holder {
  font-size: inherit;
}
.video-player__video .vjs-load-progress div {
  background: white;
}
.video-player__video .vjs-play-progress {
  background-color: black;
}
.video-player__video .vjs-slider {
  background-color: white;
}
.video-player__video .vjs-slider:focus {
  text-shadow: none;
  box-shadow: none;
  outline: 0;
}
.video-player__video .vjs-time-tooltip {
  background-color: #685bc7;
  color: white;
  border-radius: 0;
  font-family: "FKGrotesk-Regular", sans-serif;
  font-weight: 800;
  padding: 0 10px;
}
.video-player__video .vjs-play-progress::before {
  content: "";
}
.video-player__video .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover {
  transition: width 0.3s ease;
}
.video-player__video .vjs-slider-horizontal .vjs-volume-level {
  background-color: #b2b2b2;
}
.video-player__video .vjs-slider-horizontal .vjs-volume-level::before {
  content: "";
}
.video-player__video .vjs-mouse-display .vjs-time-tooltip {
  background-color: #fe5000;
}
.video-player__video:hover .vjs-big-play-button {
  background: none;
}

.slant {
  position: relative;
  z-index: 1;
}
.slant::before {
  background: inherit;
  top: 0;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  transition: transform 0.8s cubic-bezier(0.59, 0.01, 0.28, 1);
  transform: skewY(9deg);
  transform-origin: 100%;
}
.slant.is-active::before {
  transform: skewY(10deg);
}
@media (min-width: 768px) {
  .slant.is-active::before {
    transform: skewY(14deg);
  }
}
.slant--awards {
  background-color: #685bc7;
  padding-top: 6.25vw;
  padding-bottom: 12.5vw;
}
@media (min-width: 768px) {
  .slant--awards {
    padding-top: 1.6666666667vw;
    padding-bottom: 8.3333333333vw;
  }
}
.slant--awards::before {
  transform: skewY(-18deg);
  transform-origin: 0%;
}
@media (min-width: 768px) {
  .slant--awards::before {
    transform: skewY(-16deg);
  }
}
.slant--awards.is-active::before {
  transform: skewY(-18deg);
}
@media (min-width: 768px) {
  .slant--awards.is-active::before {
    transform: skewY(-19deg);
  }
}
.slant--stories {
  background-color: #00bf6f;
  padding-top: 6.25vw;
  padding-bottom: 12.5vw;
}
@media (min-width: 768px) {
  .slant--stories {
    padding-top: 1.6666666667vw;
    padding-bottom: 8.3333333333vw;
  }
}
.slant--about {
  background-color: #f2a900;
  padding-top: 6.25vw;
  padding-bottom: 12.5vw;
}
@media (min-width: 768px) {
  .slant--about {
    padding-top: 1.6666666667vw;
    padding-bottom: 16.6666666667vw;
  }
}
.slant--footer::before {
  transform: skewY(0deg);
  height: 100%;
}
.slant--footer.is-active::before {
  transform: skewY(10deg);
}
@media (min-width: 768px) {
  .slant--footer.is-active::before {
    transform: skewY(12deg);
  }
}

.form {
  width: 100%;
}
.form__input {
  border: 0;
  border-bottom: 1px solid black;
  background: transparent;
  outline: 0;
  display: block;
  width: 100%;
  padding: 0.25vw 0;
}
.form .button {
  background-color: transparent;
}

.img-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  display: block;
}
.img-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe-wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56.5%;
}
.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.social-icons li {
  display: inline-block;
  margin: 0 4px;
}

.breadcrumb {
  position: relative;
  display: inline-block;
}
@media (max-width: 767px) {
  .breadcrumb {
    padding-left: 6.25vw;
  }
}
.breadcrumb__arrow {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: transform 0.3s ease;
}
@media (min-width: 768px) {
  .breadcrumb__arrow {
    transform: translateX(calc(-100% + -1.25vw));
  }
}
.no-touchevents .breadcrumb:hover {
  cursor: pointer;
}
.no-touchevents .breadcrumb:hover .breadcrumb__arrow {
  transform: translateX(calc(-100% + -1.6666666667vw));
}

.button {
  display: inline-block;
  border: 1px solid black;
  padding: 6px 10px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: 0;
  color: black;
}
.no-touchevents .button:hover {
  background-color: white;
}
.touchevents .button:active {
  background-color: white;
}

.logo {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
}
@media (max-width: 767px) {
  .logo {
    display: none;
  }
}
.logo__wrapper {
  position: absolute;
  left: 2.5vw;
  bottom: 2.5vw;
}
.logo span {
  float: right;
  margin-right: -0.8333333333vw;
}

.toggle {
  position: absolute;
  top: 1.5625vw;
  right: 0;
}
.is-open .toggle {
  transform: rotate(180deg);
}

.caption {
  align-items: flex-start;
  display: flex;
  margin-top: 1vw;
}
.caption::before {
  content: "•";
  font-size: 3.125vw;
  margin-right: 1em;
}
@media (min-width: 768px) {
  .caption::before {
    font-size: 1vw;
  }
}

.notice {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.notice.is-active {
  transform: scale(1);
  opacity: 1;
}
.notice__message {
  background-color: #fe5000;
  padding: 1.6666666667vw 2.5vw;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
}
@media (max-width: 767px) {
  .notice__message {
    padding: 6.25vw;
  }
}

.container--unreal {
  bottom: 0;
  height: calc(100% - 5vw);
  left: 0;
  position: fixed;
  right: 0;
  top: 5vw;
  width: 100vw;
}
@media (max-width: 768px) {
  .container--unreal {
    top: 19vw;
    height: calc(100% - 19vw);
  }
}
.container--unreal iframe {
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .home:not(.is-scrolling) .nav__list,
.termsConditions:not(.is-scrolling) .nav__list,
.privacyPolicy:not(.is-scrolling) .nav__list {
    padding-bottom: 2.5vw;
  }
  .home:not(.is-scrolling) .nav__list li,
.termsConditions:not(.is-scrolling) .nav__list li,
.privacyPolicy:not(.is-scrolling) .nav__list li {
    display: list-item;
  }
  .home:not(.is-scrolling) .nav__list .menu-item,
.termsConditions:not(.is-scrolling) .nav__list .menu-item,
.privacyPolicy:not(.is-scrolling) .nav__list .menu-item {
    display: none;
  }
  .home.is-scrolling .nav__list .menu-item,
.termsConditions.is-scrolling .nav__list .menu-item,
.privacyPolicy.is-scrolling .nav__list .menu-item {
    display: list-item;
  }
}

@media (max-width: 767px) {
  .home main {
    padding-bottom: 0;
  }
}

.home .header-intro {
  padding-bottom: 0;
  height: 80vh;
}
@media (max-width: 1100px) {
  .home .header-intro {
    height: 90vh;
  }
}
@media (max-width: 768px) {
  .home .header-intro {
    height: 50vh;
  }
}
@media (max-width: 700px) {
  .home .header-intro {
    height: 70vh;
  }
}
@media (max-width: 500px) {
  .home .header-intro {
    height: 30vh;
  }
}
@media (max-width: 420px) {
  .home .header-intro {
    height: 50vh;
  }
}
@media (max-width: 340px) {
  .home .header-intro {
    height: 30vh;
  }
}
.home .header-intro img, .home .header-intro video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}
@media (max-width: 500px) {
  .home .header-intro img, .home .header-intro video {
    height: 60vh;
  }
}
@media (max-width: 420px) {
  .home .header-intro img, .home .header-intro video {
    height: 70vh;
  }
}
@media (max-width: 340px) {
  .home .header-intro img, .home .header-intro video {
    height: 50vh;
  }
}
.home .header-intro .txt-medium, .home .header-intro .form__input, .home .header-intro .button {
  font-size: 4rem;
  max-width: 70%;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .home .header-intro .txt-medium, .home .header-intro .form__input, .home .header-intro .button {
    font-size: 3rem;
    margin-top: 50px;
  }
}
@media (max-width: 768px) {
  .home .header-intro .txt-medium, .home .header-intro .form__input, .home .header-intro .button {
    margin-top: 30px;
    max-width: 90%;
  }
}
@media (max-width: 500px) {
  .home .header-intro .txt-medium, .home .header-intro .form__input, .home .header-intro .button {
    font-size: 2rem;
  }
}
@media (max-width: 340px) {
  .home .header-intro .txt-medium, .home .header-intro .form__input, .home .header-intro .button {
    font-size: 1.5rem;
  }
}

body.faq {
  background-color: #d9d9d9;
}
body.faq h1:not(.txt-large):not(.header__logo), body.faq h2:not(.txt-large):not(.header__logo), body.faq h3:not(.txt-large):not(.header__logo), body.faq h4:not(.txt-large):not(.header__logo), body.faq h5:not(.txt-large):not(.header__logo), body.faq h6:not(.txt-large):not(.header__logo) {
  text-decoration: underline;
  margin-bottom: 20px;
}

body.results section.judges {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 100px;
}
@media (max-width: 768px) {
  body.results section.judges {
    display: flex;
    flex-direction: column;
  }
}
body.results section.judges .entry__judge {
  padding-top: 20px;
  border-top: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body.results section.judges .entry__judge .entry__judge-info {
  display: flex;
  flex-direction: column;
}
body.results section.judges .entry__judge .entry__judge-info .entry__judge-name {
  display: block;
  margin-bottom: 40px;
  text-transform: uppercase;
}
body.results section.judges .entry__judge .entry__judge-info .entry__judge-role {
  margin-bottom: 5px;
}
body.results section.judges .entry__judge img {
  height: 250px;
}
@media (max-width: 992px) {
  body.results section.judges .entry__judge img {
    height: 200px;
  }
}
@media (max-width: 830px) {
  body.results section.judges .entry__judge img {
    height: 150px;
  }
}
@media (max-width: 768px) {
  body.results section.judges .entry__judge img {
    height: 200px;
  }
}