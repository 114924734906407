* {
    box-sizing: border-box;
}

::selection {
    background-color: black;
    color: white;
}

body {
    scroll-behavior: smooth;
    font-family: $sans, sans-serif;
    font-weight: 400;
    color: black;
    background-color: $blue;
    font-size: 18px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
    will-change: background-color;

    &.menu-is-open {
        position: relative;
        height: 100%;
        overflow: hidden;
    }

    &.aboutAlan {
        background-color: $yellow;
    }

    &.termsConditions,
    &.resultsByYear,
    &.results,
    &.privacyPolicy,
    &.finalist,
    &.stories {
        background-color: $off_white;
    } 

    &.stories-index {
        background-color: $green;
    }

    &.award {
        background-color: $purple;
    }
}

.background-swipe {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    .swipe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: 0 0;
        transform: scale(3);
        clip-path: polygon(100% 0, 0 0, 100% 0);
        transition: clip-path 0.8s cubic-bezier(0.59, 0.01, 0.28, 1);
        will-change: clip-path;

        .home:not(.nav-is-hovered) &[data-section='awards'] {
            background-color: $purple;
            z-index: -3;
        }

        .home:not(.nav-is-hovered) &[data-section='stories'] {
            background-color: $green;
            z-index: -2;
        }

        .home:not(.nav-is-hovered) &[data-section='about'] {
            background-color: $yellow;
            z-index: -1;
        }
    }
}

a {
    color: black;
    text-decoration: none;
}
img {
    transition: opacity 0.3s ease;

    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }

    .lazyloaded {
        opacity: 1;
    }
}

.decorated-link {
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 94%;
    background-image: -webkit-linear-gradient(top, black 75%, black 75%);
    background-image: -o-linear-gradient(top, black 75%, black 75%);
    background-image: linear-gradient(to bottom, black 75%, black 75%);

    &:hover {
        background-image: none;
    }
}

ul {
    li {
        line-height: 1.16em;
    }
}

hr {
    margin-bottom: get-vw(320, 5px);
    border: 0;
    border-bottom: 1px solid black;

    @include breakpoint-up {
        margin-bottom: get-vw(1200, 11px);
    }

    @include breakpoint-up {
        margin-bottom: get-vw(1200, 11px);
    }
}

main {
    position: relative;
    padding-top: get-vw(1200, 90px);
    padding-bottom: get-vw(1200, 300px);

    @include breakpoint-down {
        padding-bottom: get-vw(320, 60px);
        padding-top: get-vw(320, 80px);
    }

    @include breakpoint-up {
        .home & {
            padding-top: get-vw(1200, 180px);
            padding-bottom: 0;
        }

        .termsConditions &,
        .privacyPolicy & {
            padding-top: get-vw(1200, 180px);
        }
    }

    p:not(:last-child) {
        margin-bottom: 1em;
    }

    p a {
        @extend .decorated-link;
    }

    .txt-medium {
        a {
            text-decoration: none;
            @extend .decorated-link;
            &.button {
                background: none;
            }
        }

        ul {
            list-style: disc;
            padding-left: 1em;
            margin-bottom: 1em;
        }

        ol {
            list-style-type: decimal;
            padding-left: 1em;
            margin-bottom: 1em;
        }
    }
}

footer {
    position: relative;
    padding-top: get-vw(1200, 20px);
    padding-bottom: get-vw(1200, 20px);
    background-color: white;
    z-index: 1;

    @include breakpoint-down {
        padding-top: get-vw(320, 20px);
        padding-bottom: get-vw(320, 20px);
    }

    a:hover {
        text-decoration: underline;
    }

    .no-touchevents & .button:hover {
        background-color: black;
        color: white;
    }
}

.intro, .header-intro {
    @include breakpoint-up {
        height: 100vh;
        padding-bottom: 62vw;
    }

    @include breakpoint-down {
        margin-bottom: get-vw(320, 100px);
    }
}

.pos-relative {
    position: relative;
}

.footer-social {
    position: absolute;
    right: 0;
    bottom: 0;
}

.is-sticky {
    @include breakpoint-up {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: get-vw(1200, 100px);
        z-index: 9;
    }
}

.share {
    span {
        line-height: 1.5em;
    }
}

@include breakpoint-down {
    .desktop-only {
        display: none;
    }
}

@include breakpoint-up {
    .mobile-only {
        display: none;
    }
}

.award,
.home,
.aboutAlan,
.thePrize {
    img {
        //filter: grayscale(100%);
    }

    .colour,
    .colour img {
        filter: none !important;
    }

    .full {
        object-fit: contain;
    }
}

.body-copy {
    ol {
        list-style: decimal;
        list-style-position: inside;

        li {
            margin-bottom: 1em;
        }
    }
}

.reveal {
    opacity: 0;
    transition: opacity 1s ease;

    &--ed {
        opacity: 1;
    }
}

#tdp-logo {
    width: get-vw(1200, 100px);
    height: get-vw(1200, 100px);
    height: auto;

    @include breakpoint-down {
        width: get-vw(320, 60px);
        height: get-vw(320, 60px);
    }
}
