.iframe-wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56.5%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
