// Breakpoints
@mixin breakpoint-up {
  @media (min-width: #{$breakpoint_S}px) {
    @content;
  }
}

@mixin breakpoint-down {
  @media (max-width: #{$breakpoint_S - 1}px) {
    @content;
  }
}


