.judge {
    border-top: 1px solid black;
    padding-top: get-vw(1200, 15px);

    @include breakpoint-down {
        padding-top: get-vw(320, 5px);
    }

    &__profile {
        margin-bottom: get-vw(1200, 15px);
        position: relative;

        @include breakpoint-up {
            display: flex;
        }

        & > div {
            flex: 1;

            @include breakpoint-down {
                margin-bottom: get-vw(320, 20px);
            }
        }

        &__txt {
            padding-right: 10px;

            @include breakpoint-down {
                padding-right: 0;
            }
        }

        @include breakpoint-down {
            &__img {
                display: none;
            }
        }
    }

    &__name {
        margin-bottom: 0.5em;

        @include breakpoint-down {
            br {
                display: none;
            }
        }
    }

    &__role {
        display: block;
    }

    &__company {
        @extend .decorated-link;
    }

    &__bio {
        // border-top: 1px solid black;
        padding-top: get-vw(1200, 15px);

        @include breakpoint-down {
            display: none;
            padding-top: get-vw(320, 5px);
        }
    }

    &.is-open {
        .judge__profile__img,
        .judge__bio {
            display: block;
        }
    }
}
