.wrapper {
  width: 100%;
  padding-left: get-vw(320, 20px);
  padding-right: get-vw(320, 20px);

  @include breakpoint-up {
    padding-left: get-vw(1200, 30px);
    padding-right: get-vw(1200, 30px);
  }
}
