/*
*  video-player.scss
*
*  1.0 - Container
*  2.0 - Video
*  3.0 - VideoJS Overrides
*/

.video-player {
  video {
    outline: 0;
  }

  /*
  *  1.0 - Container
  */

  &__container {
    width: 100%;
    position: relative;
    padding-bottom: 57.1428571429%;
  }

  /*
  *  2.0 - Video
  */

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @extend .txt-small;
    color: black;

    /*
    *  3.0 - Video JS Overrides
    */

    .vjs-big-play-button {
      font-size: get-vw(1200, 200px);
      border: 0;
      background: none;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;

      &:focus,
      &:hover {
        outline: 0;
        border-radius: 0;
      }
    }

    &.vjs-playing .vjs-big-play-button {
      display: none;
    }

    /*
    *  3.0 - Control Bar
    */

    .vjs-button > .vjs-icon-placeholder::before {
      font-size: 2.8em;
      line-height: 1.05em;
    }

    .vjs-control:focus {
      text-shadow: none;
    }

    .vjs-control-bar {
      background-color: rgba(255, 255, 255, 0.5);
    }

    /*
    *  4.0 - Progress Bar
    */

    .vjs-progress-control {
      &:hover {
        .vjs-progress-holder {
          font-size: inherit;
        }
      }
    }

    .vjs-load-progress div {
      background: white;
    }

    .vjs-play-progress {
      background-color: black;
    }

    .vjs-slider {
      background-color: white;

      &:focus {
        text-shadow: none;
        box-shadow: none;
        outline: 0;
      }
    }

    .vjs-time-tooltip {
      background-color: $purple;
      color: white;
      border-radius: 0;
      font-family: $sans;
      font-weight: 800;
      padding: 0 10px;
    }

    .vjs-play-progress::before {
      content: "";
    }

    /*
    *  5.0 - Volume Controls
    */

    .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover {
      transition: width 0.3s ease;
    }

    .vjs-slider-horizontal .vjs-volume-level {
      background-color: #b2b2b2;

      &::before {
        content: "";
      }
    }

    .vjs-mouse-display .vjs-time-tooltip {
      background-color: $orange;
    }

    &:hover {
      .vjs-big-play-button {
        background: none;
      }
    }
  }
}
