.txt-large {
    font-family: $sans;
    font-weight: 400;
    font-size: get-vw(320, 40px);
    line-height: 1em;

    @include breakpoint-up {
        font-size: get-vw(1200, 54px);
    }
}

.txt-medium {
    font-family: $sans;
    font-weight: 400;
    font-size: get-vw(320, 16px);
    line-height: 1.16em;

    @include breakpoint-up {
        font-size: get-vw(1200, 24px);
    }
}

.txt-small {
    font-family: $sans;
    font-weight: 400;
    font-size: get-vw(320, 14px);
    line-height: 1.16em;

    @include breakpoint-up {
        font-size: get-vw(1200, 17px);
    }
}

.txt-uppercase {
    text-transform: uppercase;
    letter-spacing: -0.018em;
}

.txt-mobile-heading {
    @include breakpoint-down {
        font-size: get-vw(320, 40px);
        line-height: 0.9em;
    }
}

.txt--heading {
    display: block;
    letter-spacing: -0.018em;
    margin-bottom: 1.25vw;
    padding-top: 1.25vw;
    position: relative;
    text-transform: uppercase;

    @media (max-width: 767px) {
        margin-bottom: 1.5625vw;
        padding-top: 1.5625vw;
    }

    &:before {
        background: #000;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}
