.breadcrumb {
    position: relative;
    display: inline-block;

    @include breakpoint-down {
        padding-left: get-vw(320, 20px);
    }

    &__arrow {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: transform 0.3s ease;

        @include breakpoint-up {
            transform: translateX(calc(-100% + #{get-vw(1200, -15px)}));
        }
    }

    .no-touchevents &:hover {
        cursor: pointer;

        .breadcrumb__arrow {
            transform: translateX(calc(-100% + #{get-vw(1200, -20px)}));
        }
    }
}
