// Typography
$sans: 'FKGrotesk-Regular', sans-serif;

// Colours
$blue: #78e6d0;
$orange: rgb(254, 80, 0);
$yellow: rgb(242, 169, 0);
$off_white: rgb(217, 217, 217);
$purple: rgb(104, 91, 199);
$green: rgb(0, 191, 111);
$orange_background: #fdc843;

// Breakpoints
$breakpoint_S: 768;
