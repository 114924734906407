.post-item-results {
    h1 {
        width: 100%;
        font-size: 50px;
        border-bottom: 1px solid black;
        padding-bottom: 2.5vh !important;
        margin-bottom: 2.5vh !important;
 
    }
    &__title {
        height: auto !important;
    }
    &-border-top {
        border-top: 1px solid black !important;
    }
    &__thumb {
        img {
            width: 100%;
        }
        padding-bottom: 1.5rem !important;
        margin: 0 !important;
    }
}

.post-item-results,
.post-item {
    border-top: 1px solid black;
    padding-top: get-vw(1200, 15px);

    @include breakpoint-down {
        padding-top: get-vw(320, 5px);
    }

    &__title {
        height: 4em;
        overflow: hidden;
        position: relative;
        //border-bottom: 1px solid black;
        padding-bottom: get-vw(1200, 17px);
        margin-bottom: get-vw(1200, 15px);

        @include breakpoint-down {
            padding-bottom: get-vw(320, 10px);
            margin-bottom: get-vw(320, 9px);
        }

        h5 {
            padding-right: get-vw(1200, 100px);
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .arrow-icon {
            position: absolute;
            right: 0;
            top: 0;

            @include breakpoint-down {
                top: get-vw(320, 2px);
            }
        }
    }
    &-no-border {
        border-top: 0px solid black;
    }

    &__thumb {
        margin-bottom: get-vw(1200, 15px);
        overflow: hidden;

        @include breakpoint-up {
            position: relative;
            padding-bottom: get-vw(1200, 54px);
        }

        @include breakpoint-down {
            margin-bottom: get-vw(320, 9px);
        }

        // img {
        //     transform: scale(1);
        //     transition: all 0.3s ease;
        //     filter: grayscale(100%);
        // }

        .caption {
            //margin-top: get-vw(1200, -12px);

            @include breakpoint-up {
                height: get-vw(1200, 38px);
                margin-top: get-vw(1200, -38px);
                overflow: hidden;
                position: absolute;
                top: 100%;
            }
        }
    }

    // a {
    //     &:hover {
    //         img {
    //             transform: scale(1.02);
    //             filter: none;
    //         }

    //         .arrow-icon {
    //             animation: prod 1s ease infinite;
    //             animation-fill-mode: forwards;
    //         }
    //     }

    //     @keyframes prod {
    //         0% {
    //             transform: translate(0);
    //         }

    //         50% {
    //             transform: translate(-50%);
    //         }

    //         100% {
    //             transform: translate(0);
    //         }
    //     }
    // }
}
