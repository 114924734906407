.button {
  display: inline-block;
  border: 1px solid black;
  padding: 6px 10px;
  @extend .txt-medium;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: 0;
  color:  black;
  .no-touchevents &:hover {
    background-color: white;
  }

  .touchevents &:active {
    background-color: white;
  }
}
