.grid {
  width: 100%;
  padding-left: get-vw(320, 20px);
  padding-right: get-vw(320, 20px);
  margin-left: auto;
  margin-right: auto;

  @include breakpoint-up {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: get-vw(1200, 30px);
    padding-left: get-vw(1200, 30px);
    padding-right: get-vw(1200, 30px);
  }
}
